import { WORDS } from '../constants/wordlist';
import { VALID_GUESSES } from '../constants/validGuesses';
import {
  WRONG_SPOT_MESSAGE,
  NOT_CONTAINED_MESSAGE,
} from '../constants/strings';
import { getGuessStatuses } from './statuses';
import { default as GraphemeSplitter } from 'grapheme-splitter';
import {
  generateVariant,
  getVariantKey,
  getVariantTitle,
  variantLimits,
} from './variants';
import { EPOCH_LUXON } from '../constants/settings';
import { getVariantKeyOfDay } from './variantSchedule';
import { DateTime } from 'luxon';

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(localeAwareLowerCase(word)) ||
    VALID_GUESSES.includes(localeAwareLowerCase(word))
  );
};

export const isWinningWord = (word: string) => {
  return solution === word;
};

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export const findFirstUnusedReveal = (word: string, guesses: string[]) => {
  if (guesses.length === 0) {
    return false;
  }

  const lettersLeftArray = new Array<string>();
  const guess = guesses[guesses.length - 1];
  const statuses = getGuessStatuses(guess);
  const splitWord = unicodeSplit(word);
  const splitGuess = unicodeSplit(guess);

  for (let i = 0; i < splitGuess.length; i++) {
    if (statuses[i] === 'correct' || statuses[i] === 'present') {
      lettersLeftArray.push(splitGuess[i]);
    }
    if (statuses[i] === 'correct' && splitWord[i] !== splitGuess[i]) {
      return WRONG_SPOT_MESSAGE(splitGuess[i], i + 1);
    }
  }

  // check for the first unused letter, taking duplicate letters
  // into account - see issue #198
  let n;
  for (const letter of splitWord) {
    n = lettersLeftArray.indexOf(letter);
    if (n !== -1) {
      lettersLeftArray.splice(n, 1);
    }
  }

  if (lettersLeftArray.length > 0) {
    return NOT_CONTAINED_MESSAGE(lettersLeftArray[0]);
  }
  return false;
};

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word);
};

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length;
};

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase();
};

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase();
};

export const getVariantOfDay = (data: {
  solution: string;
  solutionIndex: number;
}): string => {
  let variantKey = getVariantKeyOfDay(data.solutionIndex);

  return generateVariant(data.solution, variantKey);
};

const shiftWord = (word: string, shift: number) => {
  if (shift === 0) {
    return word;
  }
  return `${word.substr(shift)}${word.substr(0, shift)}`;
}

const modifySolutionAndSettingsForVariant = (variant: string, solution: string): string => {
  if (getVariantKey(variant) === 'SHIF1') {
    const wordlistLength = WORDS.length;
    const validGuessesLength = VALID_GUESSES.length;
    for (let i = 0; i < wordlistLength; ++i) {
      WORDS.push(shiftWord(WORDS[i], 1));
      WORDS.push(shiftWord(WORDS[i], 4));
    }
    for (let i = 0; i < validGuessesLength; ++i) {
      VALID_GUESSES.push(shiftWord(VALID_GUESSES[i], 1));
      VALID_GUESSES.push(shiftWord(VALID_GUESSES[i], 4));
    }
    return shiftWord(solution, parseInt(variant[6]));
  }
  if (getVariantKey(variant) === 'SHIF2') {
    const wordlistLength = WORDS.length;
    const validGuessesLength = VALID_GUESSES.length;
    for (let i = 0; i < wordlistLength; ++i) {
      WORDS.push(shiftWord(WORDS[i], 1));
      WORDS.push(shiftWord(WORDS[i], 2));
      WORDS.push(shiftWord(WORDS[i], 3));
      WORDS.push(shiftWord(WORDS[i], 4));
    }
    for (let i = 0; i < validGuessesLength; ++i) {
      VALID_GUESSES.push(shiftWord(VALID_GUESSES[i], 1));
      VALID_GUESSES.push(shiftWord(VALID_GUESSES[i], 2));
      VALID_GUESSES.push(shiftWord(VALID_GUESSES[i], 3));
      VALID_GUESSES.push(shiftWord(VALID_GUESSES[i], 4));
    }
    return shiftWord(solution, parseInt(variant[6]));
  }
  return solution;
};

export const getWordOfDay = () => {
  const now = Date.now();
  const epoch = DateTime.fromObject(EPOCH_LUXON);
  let index = Math.floor(-epoch.diffNow('days').days);
  let nextDay = DateTime.now().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  }).plus({
    day: 1
  }).toMillis();

  if (index < 0) {
    const msInDay = 86400000;
    nextDay -= msInDay * index;
    index = 0;
  }

  if (process.env.REACT_APP_SPECIAL_MODE === 'chaoticiteration') {
    index = now;
  }
  let solution = localeAwareUpperCase(WORDS[index % WORDS.length]);

  const variant = getVariantOfDay({
    solution,
    solutionIndex: index,
  });

  solution = modifySolutionAndSettingsForVariant(variant, solution);

  return {
    solution,
    solutionIndex: index,
    tomorrow: nextDay,
    variant,
    variantKey: getVariantKey(variant),
    variantTitle: getVariantTitle(variant),
    maxChallenges: variantLimits[getVariantKey(variant)],
  };
};

export const {
  solution,
  solutionIndex,
  tomorrow,
  variant,
  variantKey,
  variantTitle,
  maxChallenges,
} = getWordOfDay();
